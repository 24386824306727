import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import VLibras from "@djpfs/react-vlibras";
import Cabecalho from "./componentes/Cabecalho";
import Inicio from "./componentes/Inicio";
import Footer from "./componentes/Footer";
import PaginaNaoEncontrada from "./componentes/404";
import ViewNoticia from "./componentes/ViewNoticia";
import SalaEmpreendedor from "./componentes/SalaEmpreendedor";
import Transparencia from "./componentes/Transparencia";
import Solicitacoes from "./componentes/rh/Solicitacoes";
import FichaFuncional from "./componentes/rh/FichaFuncional";
import ContraCheque from "./componentes/rh/ContraCheque";
import LeiPauloGustavo from "./componentes/leipaulogustavo";
import GurjaoPlus from "./componentes/app/GurjaoPlus";
import NotaFiscal from "./componentes/app/NotaFiscal";
import CodigoTributario from "./componentes/legislacao/CodigoTributario";
import Decretos from "./componentes/legislacao/Decretos";
import DiarioOficial from "./componentes/legislacao/DiarioOficial";
import LeisMunicipais from "./componentes/legislacao/Leismunicipais";
import LeiOrganica from "./componentes/legislacao/Leiorganica";
import Portarias from "./componentes/legislacao/Portarias";
import Editais from "./componentes/arquivos/Editais";
import LDO from "./componentes/arquivos/LDO";
import LOA from "./componentes/arquivos/LOA";
import RGF from "./componentes/arquivos/RGF";
import RREO from "./componentes/arquivos/RREO";

import IPTU from "./componentes/app/IPTU";
import Financas from "./componentes/secretarias/Financas";
import Educacao from "./componentes/secretarias/Educacao";
import Administracao from "./componentes/secretarias/Administracao";
import Cultura from "./componentes/secretarias/Cultura";
import Saude from "./componentes/secretarias/Saude";
import DesenvolvimentoRural from "./componentes/secretarias/DesenvolvimentoRural";
import AssistenciaSocial from "./componentes/secretarias/AssistenciaSocial";
import Prefeito from "./componentes/prefeitura/Prefeito";
import VicePrefeito from "./componentes/prefeitura/VicePrefeito";
import OuvidoriaPage from "./componentes/prefeitura/Ouvidoria";
import LoadingScreen from 'react-loading-screen'
import Contratos from "./componentes/arquivos/Contratos";

import Frota from "./componentes/arquivos/Frota";
import TodasNoticias from "./componentes/TodasNoticias";
import Contato from './componentes/app/Contato';
import Licitacoes from './componentes/Licitacoes';


import Avisos from './componentes/Avisos';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import Rh from './componentes/secretarias/rh';
import Quadro from './componentes/arquivos/Quadro';
import ProcessoSeletivo from './componentes/legislacao/Processoseletivo';
import FarmaciaBasica from './componentes/arquivos/FarmaciaBasica';
import ControleInterno from './componentes/legislacao/ControleInterno';
import MeioAmbiente from './componentes/secretarias/MeioAmbiente';
import AldirBlanc from './componentes/legislacao/aldir_blanc';

import { getDatabase, ref, get, onValue } from 'firebase/database';
import firebaseApp, { camara, KEY_REF } from './componentes/adm/admin/firebaseConfig';
import SaoJoao from './componentes/app/JoaoJoao';
import TicketDetails from './componentes/app/TicketDetail';
import HistoriaGurjaoPage from './componentes/prefeitura/Historia';
import ESICPage from './componentes/prefeitura/ESICPage';
import ServiceLetterPage from './componentes/prefeitura/CartaServico';
import LgpdInfoPage from './componentes/prefeitura/LGPD';
import GaleriaMidiaPage from './componentes/prefeitura/GaleriaMidiaPage ';
import PoliticaPrivacidadePage from './componentes/prefeitura/gurjaoplus';
import PCA from './componentes/arquivos/PCA';
import TabelaDiariasMunicipais from './componentes/utils/TabelaDiarias';
import Header from './componentes/utils/Header';
import ViewVereador from './componentes/ViewVereador';
import Requerimentos from './componentes/legislacao/Requerimentos';
import ProjetoDeLei from './componentes/legislacao/ProjetoDeLei';
import Mocoes from './componentes/legislacao/Mocoes';
import Pedidos from './componentes/legislacao/Pedidos';
import Mesadiretora from './componentes/legislacao/Mesadiretora';
import { passConfigToOtherComponent } from './componentes/adm/ConfigContext'; 
import Infraestrutura from './componentes/secretarias/Infraestrutura';
import ConcursoPublico from './componentes/arquivos/ConcursoPublico';

const database = getDatabase(firebaseApp);

function App() {
  const [loading, setLoading] = useState(true);
  const [config, setConfig] = useState(null);


  useEffect(() => {
    const dbRef = ref(database, `${KEY_REF}/config/`);

    setLoading(true);

    const unsubscribe = onValue(dbRef, (snapshot) => { 
      if (snapshot.exists()) {
        passConfigToOtherComponent(snapshot.val());
        setConfig(snapshot.val());
      } else {
        setConfig(null);
      }
      setLoading(false);
    }, (error) => {
      console.error("Error fetching config:", error);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);




  if (loading) {
    return <LoadingScreen
      loading={true}
      bgColor='white'
      spinnerColor='#9ee5f8'
      textColor='#676767'
      logoSrc='/logo512.png'
    />
  }

  return (
    <Router>
      <Cabecalho config={config} />
      {/* <Header/> */}


      <FloatingWhatsApp
        phoneNumber={config?.telefone}
        accountName={config?.orgaoNome}
        avatar="/logo512.png"
        statusMessage="Geralmente Responde em 30 minutos"
        allowEsc
        allowClickAway
        notification
        notificationSound="true"
        messageDelay="1"
        placeholder="Escreva uma mensagem..."
        chatMessage="Bem-vindos! Em que posso ajudar?"
        notificationDelay="10"
      />

      <VLibras forceOnload={true}></VLibras>

      <Routes>
        <Route path="/vereadores/:id" element={<ViewVereador />} />
        <Route path="/" element={<Inicio config={config}/>} />
        <Route path="/noticias/:titulo/:id" element={<ViewNoticia />} />
        <Route path="/noticias/todas" element={<TodasNoticias />} />
        <Route path="/transparencia" element={<Transparencia config={config} />} />
        {!camara && <Route path="/saladoempreendedor" element={<SalaEmpreendedor />} />}
        {!camara && <Route path="/leipaulogustavo" element={<LeiPauloGustavo />} />}
        <Route path="/avisos" element={<Avisos />} />
        {!camara && <Route path="/processo_seletivo" element={<ProcessoSeletivo />} />}
        {!camara && <Route path="/rh/documentos" element={<Solicitacoes />} />}
        {!camara && <Route path="/rh/fichafuncional" element={<FichaFuncional />} />}
        {!camara && <Route path="/rh/cc" element={<ContraCheque />} />}
        {!camara && <Route path="/app/gurjaoplus" element={<GurjaoPlus />} />}
        {!camara && <Route path="/app/gurjaoplus/politicadeprivacidade" element={<PoliticaPrivacidadePage />} />}
        {!camara && <Route path="/app/notasfiscais" element={<NotaFiscal config={config} />} />}
        {!camara && <Route path="/app/iptu" element={<IPTU config={config} />} />}
        {!camara && <Route path="/app/acessorapido" element={<TodasNoticias />} />}
        <Route path="/app/perguntas" element={<Contato />} />
        {!camara && <Route path="/turismo/bodenarua" element={<SaoJoao />} />}
        <Route path="/ticket/:ticketId" component={<TicketDetails />} />
        <Route path="/legislacao/codigotributario" element={<CodigoTributario />} />
        <Route path="/legislacao/decretos" element={<Decretos />} />
        {!camara && <Route path="/legislacao/aldir_blanc" element={<AldirBlanc />} />}
        {camara && <Route path="/mesadiretora" element={<Mesadiretora />} />}
        <Route path="/legislacao/diariosoficial" element={<DiarioOficial />} />
        <Route path="/legislacao/requerimentos" element={<Requerimentos />} />
        <Route path="/legislacao/leiorganica" element={<LeiOrganica />} />
        <Route path="/legislacao/projetodelei" element={<ProjetoDeLei />} />
        <Route path="/legislacao/mocoes" element={<Mocoes />} />
        <Route path="/legislacao/pedidos_info" element={<Pedidos />} />
        <Route path="/legislacao/leismunicipais" element={<LeisMunicipais />} />
        <Route path="/legislacao/portarias" element={<Portarias />} />
        <Route path="/legislacao/controleinterno" element={<ControleInterno />} />
        {!camara && <Route path="/licitacoes" element={<Licitacoes />} />}
        <Route path="/arquivos/editais" element={<Editais />} />
        <Route path="/arquivos/contratos" element={<Contratos />} />
        <Route path="/arquivos/ldo" element={<LDO />} />
        <Route path="/arquivos/concursos" element={<ConcursoPublico />} />
        <Route path="/arquivos/loa" element={<LOA />} />
        {!camara && <Route path="/arquivos/rgf" element={<RGF />} />}
        <Route path="/arquivos/tabela-diarias" element={<TabelaDiariasMunicipais />} />
        <Route path="/arquivos/pca" element={<PCA />} />
        {!camara && <Route path="/arquivos/rreo" element={<RREO />} />}
        <Route path="/arquivos/frota" element={<Frota />} />
        <Route path="/arquivos/quadro" element={<Quadro />} />
        {!camara && <Route path="/arquivos/farmaciabasica" element={<FarmaciaBasica />} />}
        {!camara && <Route path="/secretarias/financas" element={<Financas />} />}
        {!camara && <Route path="/secretarias/infraestrutura" element={<Infraestrutura />} />}
        {!camara && <Route path="/secretarias/educacao" element={<Educacao />} />}
        {!camara && <Route path="/secretarias/administracao" element={<Administracao />} />}
        {!camara && <Route path="/secretarias/cultura" element={<Cultura />} />}
        {!camara && <Route path="/secretarias/saude" element={<Saude />} />}
        {!camara && <Route path="/secretarias/meioambiente" element={<MeioAmbiente />} />}
        {!camara && <Route path="/secretarias/desenvolvimentorural" element={<DesenvolvimentoRural />} />}
        {!camara && <Route path="/secretarias/assistenciasocial" element={<AssistenciaSocial />} />}
        {!camara && <Route path="/setor/recursoshumanos" element={<Rh />} />}
        {!camara && <Route path="/prefeitura/prefeito" element={<Prefeito />} />}
        <Route path="/e-sic" element={<ESICPage />} />
        <Route path="/galeria" element={<GaleriaMidiaPage />} />
        {!camara && <Route path="/prefeitura/lgpd" element={<LgpdInfoPage />} />}
        {!camara && <Route path="/prefeitura/carta-de-servico" element={<ServiceLetterPage />} />}
        <Route path="/ouvidoria" element={<OuvidoriaPage />} />
        {!camara && <Route path="/prefeitura/viceprefeito" element={<VicePrefeito />} />}
        <Route path="/historia" element={<HistoriaGurjaoPage config={config} />} />
        <Route path="*" element={<PaginaNaoEncontrada />} />
      </Routes>

      <Footer config={config} />
    </Router>
  );
}

export default App;
